import { render, staticRenderFns } from "./newrole.vue?vue&type=template&id=4dd54da8&scoped=true&"
import script from "./newrole.vue?vue&type=script&lang=js&"
export * from "./newrole.vue?vue&type=script&lang=js&"
import style0 from "./newrole.vue?vue&type=style&index=0&id=4dd54da8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dd54da8",
  null
  
)

export default component.exports